import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import styles from './header.module.scss'
import ArrowDown from '../../assets/arrow_down.svg'
import LanguageIcon from '../../assets/language.svg'
import LogoIcon from '../../assets/logo.svg'
import { isMainnet, changeLanguage, currentLanguage } from '../../utils'

const links = [
  {
    label: 'mainnet',
    url: process.env.REACT_APP_MAINNET_SITE_URL,
  },
  {
    label: 'testnet',
    url: process.env.REACT_APP_TESTNET_SITE_URL,
  },
]

const showLanguage = (isReverse = false) => {
  if (isReverse) {
    return currentLanguage() === 'zh' ? 'en' : 'zh'
  }
  return currentLanguage() === 'zh' ? 'zh' : 'en'
}

export default () => {
  const [t] = useTranslation()
  const [netEl, setNetEl] = useState<HTMLDivElement | null>(null)
  const [languageEl, setLanguageEl] = useState<HTMLDivElement | null>(null)

  const changeAppLanguage = () => {
    const language = showLanguage(true)
    changeLanguage(language)
    setLanguageEl(null)
  }

  const gotoUrl = (url: string) => {
    // eslint-disable-next-line no-restricted-globals
    location.href = url
    setNetEl(null)
  }

  useEffect(() => {
    const move = (event: MouseEvent) => {
      if (!netEl && !languageEl) {
        return
      }
      const isInMenu = Boolean((event.target as Element).closest(`.${styles.menuSwitch}, .MuiPaper-root`))
      if (!isInMenu) {
        setNetEl(null)
        setLanguageEl(null)
      }
    }
    window.addEventListener('mousemove', move)
    return () => window.removeEventListener('mousemove', move)
  })

  return (
    <div className={styles.header}>
      <Link className={styles.logo} to="/">
        <img src={LogoIcon} alt="logo" title="NFT Explorer" />
      </Link>

      <div
        className={styles.menuSwitch}
        onClick={e => setNetEl(e.currentTarget)}
        onMouseEnter={e => {
          setNetEl(e.currentTarget)
        }}
      >
        <div className={styles.menuSwitchName}>
          {t(isMainnet ? 'mainnet' : 'testnet')}
          <img className={styles.arrow} src={ArrowDown} alt="more" />
        </div>
      </div>

      <div
        className={[styles.menuSwitch, styles.inRight].join(' ')}
        onClick={e => setLanguageEl(e.currentTarget)}
        onMouseEnter={e => {
          setLanguageEl(e.currentTarget)
        }}
      >
        <div className={styles.menuSwitchName}>
          <img className={styles.icon} src={LanguageIcon} alt="lan" />
          <div className={styles.language}>{t(showLanguage())}</div>
          <img className={styles.arrow} src={ArrowDown} alt="more" />
        </div>
      </div>

      <Menu anchorEl={netEl} open={Boolean(netEl)} onClose={() => setNetEl(null)}>
        {links.map((link, key) => (
          <MenuItem onClick={() => gotoUrl(link.url || '/')} key={String(key) + link.label}>
            {t(link.label)}
          </MenuItem>
        ))}
      </Menu>

      <Menu anchorEl={languageEl} open={Boolean(languageEl)} onClose={() => setLanguageEl(null)}>
        {showLanguage() === 'en' ? (
          <>
            <MenuItem onClick={changeAppLanguage}>{t(showLanguage(true))}</MenuItem>
            <MenuItem onClick={() => setLanguageEl(null)}>{t(showLanguage())}</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={() => setLanguageEl(null)}>{t(showLanguage())}</MenuItem>
            <MenuItem onClick={changeAppLanguage}>{t(showLanguage(true))}</MenuItem>
          </>
        )}
      </Menu>
    </div>
  )
}
