import { useEffect, useRef, useState } from 'react'
import { changeLanguage, copy, isMainnet } from '../utils'
import { MAINNET_CACHE_LANGUAGE, TESTNET_CACHE_LANGUAGE } from '../constants'
import { fetchCachedData } from '../utils/cache'

export const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef(() => {})
  useEffect(() => {
    savedCallback.current = callback
  })
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    const listener = setTimeout(tick, delay)
    return () => {
      clearTimeout(listener)
    }
  }, [delay])
}

export const useCopy = (content: string, options?: { delay?: number }) => {
  const [isCopied, setIsCopied] = useState(false)
  const onCopy = () => {
    if (isCopied) {
      return
    }
    copy(content)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, options?.delay ?? 2000)
  }
  return {
    isCopied,
    onCopy,
  }
}

const initAppLanguage = (systemLanguage: string) => {
  const defaultLanguage = (systemLanguage.substring(0, 2) as Language) || 'en'
  changeLanguage(
    fetchCachedData<Language>(isMainnet ? MAINNET_CACHE_LANGUAGE : TESTNET_CACHE_LANGUAGE) || defaultLanguage,
  )
}

export const useInitApp = () => {
  const [init, setInit] = useState(false)
  const { language } = window.navigator

  if (!init) {
    setInit(true)
    initAppLanguage(language)
  }
}

export default useInitApp
