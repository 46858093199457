import { useTranslation } from 'react-i18next'
import { getCurrentYear } from '../../utils'
import styles from './footer.module.scss'
import LogoIcon from '../../assets/logo.svg'

const linkGroups: Array<[string, ...Array<[string, string]>]> = [
  [
    'related_links',
    ['official_website', process.env.REACT_APP_OFFICIAL_WEBSITE_URL!],
    ['golden_legend', process.env.REACT_APP_GOLDEN_LEGEND_URL!],
    ['wallet', process.env.REACT_APP_WALLET_URL!],
  ],
  ['others', ['contact_us', process.env.REACT_APP_EMAIL_US_URL!]],
]

export default () => {
  const [t] = useTranslation()
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={LogoIcon} alt="logo" />
        </div>
        <div className={styles.links}>
          {linkGroups.map(([title, ...links]) => (
            <div key={title}>
              <span>{t(title)}</span>
              {links.map(([label, url]) => (
                <a href={url} target="_blank" rel="noopener noreferrer" key={label}>
                  {t(label)}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.copyright}>
        <span>
          {t('copyright', {
            year: getCurrentYear(),
          })}
        </span>
      </div>
    </div>
  )
}
