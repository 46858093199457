import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import useInitApp from './hooks'
import Routers from './routers'

const App = () => {
  const [t] = useTranslation()
  useInitApp()

  return (
    <div className="app-panel">
      <Helmet>
        <title>{t('app_name')}</title>
        <meta name="description" content={t('app_description')} />
        <meta name="og:title" content={t('app_name')} />
        <meta name="og:description" content={t('app_description')} />
      </Helmet>
      <Routers />
    </div>
  )
}

export default App
