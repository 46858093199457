import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import zh from '../locales/zh.json'
import en from '../locales/en.json'
import { fetchCachedData, storeCachedData } from './cache'
import { MAINNET_CACHE_LANGUAGE, TESTNET_CACHE_LANGUAGE } from '../constants'
import { isMainnet } from './util'

i18n.use(initReactI18next).init({
  resources: {
    zh,
    en,
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export const changeLanguage = (lan: Language) => {
  if (lan.indexOf('zh') !== -1) {
    i18n.changeLanguage('zh')
  } else {
    i18n.changeLanguage('en')
  }
  storeCachedData(isMainnet ? MAINNET_CACHE_LANGUAGE : TESTNET_CACHE_LANGUAGE, lan)
}

export const currentLanguage = (): Language | null => {
  return fetchCachedData<Language>(isMainnet ? MAINNET_CACHE_LANGUAGE : TESTNET_CACHE_LANGUAGE)
}
