import dayjs from 'dayjs'

export const getCurrentYear = () => new Date().getFullYear()

export const parseDateWithoutSecond = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm')
}

export const parseSimpleDate = (timestamp: number) => {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
}
