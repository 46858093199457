import type { ChangeEvent } from 'react'
import camelcaseKeys from 'camelcase-keys'
import { AddressPrefix, parseAddress as parseCKBAddress } from '@nervosnetwork/ckb-sdk-utils'
import { TFunction } from 'i18next'
import { isMobile, isScreenSmaller1000 } from './screen'
import LoadingIcon from '../assets/loading.svg'
import { OSS_IMG_HOSTS, OSS_IMG_PROCESS_QUERY_KEY, OSS_IMG_PROCESS_QUERY_KEY_SCALE } from '../constants'
import FallbackIcon from '../assets/fallback.svg'

export const toCamelcase = (object: any): any => {
  try {
    return JSON.parse(
      JSON.stringify(
        camelcaseKeys(object, {
          deep: true,
        }),
      ),
    )
  } catch (error) {
    console.error(`Parse object to camelCase error: ${error}`)
  }
  return null
}

export const parseAddress = (value: string, addressType = 'default' as AddressType) => {
  let length = isMobile() ? 7 : 12
  switch (addressType) {
    case 'short':
      length = isMobile() ? 5 : 6
      break
    case 'long':
      length = isMobile() ? 10 : 24
      break
    default:
      break
  }
  return `${value.substr(0, length)}...${value.substr(value.length - length, length)}`
}

export const ellipsisString = (value: string, range: [number, number]) => {
  return `${value.substring(0, range[0])}...${value.substring(value.length - range[1])}`
}

export const isError = (result: Response.Result<any>) => {
  return result === null || (result && result instanceof Error)
}

export const isListError = (result: Response.Result<any>) => {
  return isError(result) || (result as List).meta.totalCount === 0
}

export const isMainnet = process.env.REACT_APP_CHAIN_TYPE === 'mainnet'
export const isCkbAddress = (address?: string) => {
  if (!address) return false
  if (isMainnet) {
    if (!address.startsWith(AddressPrefix.Mainnet)) {
      return false
    }
  } else if (!address.startsWith(AddressPrefix.Testnet)) {
    return false
  }
  try {
    parseCKBAddress(address)
  } catch {
    return false
  }

  return true
}

export const isIssuerId = (str: string): boolean => {
  return str.length === 42 && str.startsWith('0x')
}

export const isTypeArgs = (str: string): boolean => {
  return str.startsWith('0x')
}

const defaultPage = 1
export const parsePage = (value: string | string[] | null) => {
  if (typeof value !== 'string') {
    return defaultPage
  }
  return parseInt(value, 10) || defaultPage
}

export const parseRows = (): { rows: number[]; step: number } => {
  if (isMobile()) {
    return {
      rows: [0, 1, 2, 3, 4, 5, 6, 7],
      step: 1,
    }
  }
  if (isScreenSmaller1000()) {
    return {
      rows: [0, 2, 4, 6],
      step: 2,
    }
  }
  return {
    rows: [0, 4],
    step: 4,
  }
}

export const handleNftImgError = (e: ChangeEvent<HTMLImageElement>): void => {
  e.target.src = FallbackIcon
  e.target.style.padding = '20px'
}

export const handleLoadingNftImage = (e: ChangeEvent<HTMLImageElement>): void => {
  e.target.src = LoadingIcon
}

export const displayVerifiedInfo = (t: TFunction, verifiedInfo?: API.VerifiedInfo) => {
  return `${verifiedInfo?.verifiedSource === 'weibo' ? t('weibo_auth') : ''}${verifiedInfo?.verifiedTitle}`
}

export function isUsdz(url?: string) {
  if (!url) return url
  try {
    const urlObj = new URL(url)
    return /.usdz$/.test(urlObj.pathname)
  } catch {
    return false
  }
}

export const addParamsToUrl = (
  url: string,
  params: { [key: string]: string },
  options?: {
    ignoreDuplicates?: boolean
  },
): string => {
  if (!url) {
    return url
  }
  const urlObj = new URL(url)
  const query = Object.fromEntries(urlObj.searchParams)
  urlObj.search = new URLSearchParams(
    options?.ignoreDuplicates
      ? {
          ...query,
          ...params,
        }
      : {
          ...params,
          ...query,
        },
  ) as any
  return decodeURIComponent(urlObj.toString())
}

export const getImagePreviewUrl = (
  url: string,
  options?: {
    size?: number
    outLinkSize?: string
  },
): string => {
  if (!url) {
    return url as any
  }
  const size = options?.size ?? 300
  try {
    const urlObj = new URL(url)
    const isOssHost = OSS_IMG_HOSTS.some(host => url?.startsWith(host))
    const isSkip = /\.(svg|webp|gif)$/i.test(urlObj.pathname)
    if (!isOssHost) {
      return addParamsToUrl(url, {
        size: options?.outLinkSize ?? 'large',
      })
    }
    if (isSkip) {
      return url
    }
    const params: { [key in typeof OSS_IMG_PROCESS_QUERY_KEY]?: string } = {
      [OSS_IMG_PROCESS_QUERY_KEY]: `${OSS_IMG_PROCESS_QUERY_KEY_SCALE}${size}`,
    }
    return addParamsToUrl(url, params)
  } catch {
    return url
  }
}

export const addTidToUrl = (url: string, tid: string): string => {
  if (!url) {
    return url ?? ''
  }
  return addParamsToUrl(url, {
    tid,
  })
}

export const addLocaleToUrl = (url: string, locale: 'zh' | 'en'): string => {
  if (!url) {
    return url ?? ''
  }
  return addParamsToUrl(url, {
    locale,
  })
}

export const isPlayableMedia = (type: RendererType) => type === 'audio' || type === 'video'

export const copy = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value)
  } catch {
    const input = document.createElement('input')
    input.readOnly = true
    input.value = value
    input.style.position = 'absolute'
    input.style.width = '100px'
    input.style.left = '-10000px'
    document.body.appendChild(input)
    input.select()
    input.setSelectionRange(0, input.value.length)
    document.execCommand('copy')
    document.body.removeChild(input)
  }
}

export const formatCkbAddress = (addr: string) => `${addr.substr(0, 7)}...${addr.slice(-4)}`

export const holderAddress = (addr: string) => `${addr.substr(0, 8)}...${addr.slice(-8)}`
