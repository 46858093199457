export const API_URL = process.env.REACT_APP_API_SERVER
export const RESIZE_LATENCY = 500
export const LOADING_WAIT_TIME = 1000
export const DATA_LOAD_PERIOD = 5000

export const TOKENS_PAGE_LIMIT = 10
export const TXS_PAGE_LIMIT = 8
export const SEARCH_PAGE_LIMIT = 20

export const OVERFLOW_ERROR_CODE = 1003

export const MAINNET_CACHE_LANGUAGE = 'mainnet_cache_language'
export const TESTNET_CACHE_LANGUAGE = 'testnet_cache_language'

export const OSS_IMG_PROCESS_QUERY_KEY = 'x-oss-process'
export const OSS_IMG_PROCESS_QUERY_KEY_SCALE = 'image/resize,s_'
export const OSS_IMG_HOSTS = [
  'https://oss.jinse.cc',
  'https://goldenlegend.oss-cn-hangzhou.aliyuncs.com',
  'https://goldenlegend.oss-accelerate.aliyuncs.com',
]

export const IS_ANDROID = navigator.userAgent.toLowerCase().includes('android')
export const IS_WECHAT = navigator.userAgent.toLowerCase().includes('micromessenger')
export const IS_UNITTEST = process.env.NODE_ENV === 'test'
export const IS_SUPPORT_AR = !IS_WECHAT && !IS_UNITTEST && document.createElement('a').relList.supports('ar')
