import { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Page from '../components/Page'

const Home = lazy(() => import('../pages/Home'))
const Transaction = lazy(() => import('../pages/Transaction'))
const Holder = lazy(() => import('../pages/Holder'))
const Issuer = lazy(() => import('../pages/Issuer'))
const Nft = lazy(() => import('../pages/Nft'))
const PageLost = lazy(() => import('../pages/PageLost'))
const DomainMigration = lazy(() => import('../pages/DomainMigration'))

const Containers: CustomRouter.Route[] = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    comp: Home,
  },
  {
    name: 'Transaction',
    path: '/transaction/:uuid',
    exact: true,
    comp: Transaction,
  },
  {
    name: 'Holder',
    path: '/holder/:tab/:address',
    exact: true,
    comp: Holder,
  },
  {
    name: 'Issuer',
    path: '/issuer/:tab/:uuid',
    exact: true,
    comp: Issuer,
  },
  {
    name: 'Nft',
    path: '/nft/:uuid',
    exact: true,
    comp: Nft,
  },
  {
    name: 'Nft',
    path: '/nft/:uuid/:tid',
    exact: true,
    comp: Nft,
  },
  {
    name: 'DomainMigration',
    path: '/domain_migration',
    exact: true,
    comp: DomainMigration,
  },
  {
    name: '404',
    path: '/404',
    exact: true,
    comp: PageLost,
  },
]

const useRouter = (callback: Function) => {
  const history = createBrowserHistory()
  useEffect(() => {
    let currentUrl = `${history.location.pathname}${history.location.search}`
    const listen = history.listen((location: any) => {
      if (currentUrl !== `${location.pathname}${location.search}`) {
        callback()
      }
      currentUrl = `${location.pathname}${location.search}`
    })
    return () => {
      listen()
    }
  }, [callback, history])
}

export default () => {
  useRouter(() => {
    window.scrollTo(0, 0)
  })

  return (
    <Router basename="/">
      <Route
        render={(props: any) => (
          <Page>
            <Header />
            <Suspense fallback={<span />}>
              <Switch location={props.location}>
                {Containers.map(container => (
                  <Route
                    {...container}
                    key={container.name}
                    render={routeProps => <container.comp {...routeProps} />}
                  />
                ))}
                <Redirect from="*" to="/404" />
              </Switch>
              <Footer />
            </Suspense>
          </Page>
        )}
      />
    </Router>
  )
}
